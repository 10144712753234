import { createRouter, createWebHashHistory, RouteRecordRaw } from 'vue-router'


const routes: Array<RouteRecordRaw> = [
  /*{
    path: '/about',
    name: 'About',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/!* webpackChunkName: "about" *!/ '../views/About.vue')
  }*/
  {
    path:"/",
    redirect:"/home",
    component: () => import('../components/layout/header/index.vue'),
    children: [
      {
        path: '/home',
        name: 'home',
        component: ()=>import("../views/home.vue")
      },
      {
        path: '/study',
        name: 'study',
        component: () => import('@/views/Study/index.vue')
      },
      {
        path: '/game',
        name: 'game',
        children: [
          {
            path: 'snake',
            name: 'snake',
            component: ()=>import("../views/Game/Snake.vue")
          }
        ]
      }
    ]
  },
]

const router = createRouter({
  history: createWebHashHistory(),
  routes
})

export default router
